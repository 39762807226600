import React from "react";
import VolgaCTFLogo from "../../assets/images/logo/logo.svg"
import {Link, useI18next} from "gatsby-plugin-react-i18next";
import {graphql, useStaticQuery} from "gatsby";

const Navigation = () => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            ctfJson(current: {eq: true}){
                slug
                title
            }
        }
    `);
    const currentCtfSlug = data.ctfJson.slug;
    const currentCtfName = data.ctfJson.title;
    const {originalPath, t} = useI18next()

    return (<header>
        <div className="grid-x">
            <div className="large-2 cell large-offset-1 show-for-large">
                <img className="logo" src={VolgaCTFLogo} alt="VolgaCTF logo"/>
            </div>
            <div className="large-6 large-offset-2 cell">
                <ul className="menu align-center">
                    <li>
                        <Link to="/" activeClassName="active">{t("main_page")}</Link>
                    </li>
                    <li>
                        <Link to={currentCtfSlug} activeClassName="active">{currentCtfName.toUpperCase()}</Link>
                    </li>
                    <li>
                        <Link to={"/contacts/"} activeClassName={"active"}>{t("contacts")}</Link>
                    </li>
                    <li>
                        <Link to={"/archive/"} activeClassName={"active"}>{t("archive")}</Link>
                    </li>
                </ul>
            </div>
            <div className="large-1 cell medium-centered text-center language-selector">
                <Link to={originalPath} language="ru" activeClassName={"bold"}>RU</Link>
                /
                <Link to={originalPath} language="en" activeClassName={"bold"}>EN</Link>
            </div>
        </div>
    </header>)
}

export default Navigation
