import React from "react";
import "../styles/global.scss"
import Navigation from "./nav/navigation";
import VolgaCTFWhiteLogo from "../assets/images/logo/logo_white.svg"

const Layout = ({children}) => {
    return (
        <div className="flex-container flex-dir-column height-screen">
            <div className="flex-child-grow">
                <Navigation/>
                <main>
                    {children}
                </main>
            </div>
            <footer>
                <div className="grid-x align-middle">
                    <div className="large-offset-9 large-2 cell text-right show-for-large">
                        <img src={VolgaCTFWhiteLogo} alt="VolgaCTF white logo"/>
                    </div>
                </div>
            </footer>
        </div>


    )
}

export default Layout
